/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { Form, withFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../components/Spinner';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from '../components/FormField';
import { applyDiscount } from '../state/basket';

const DiscountForm = ({ setFieldValue, isSubmitting, translate }) => {
  const [showButton, setShowButton] = useState(false);
  const onInputChange = useCallback(
    e => {
      const { value } = e.target;
      setFieldValue('code', value.toUpperCase().replace(/[^A-ZÅÄÖ0-9- ]/g, '')); // remove non-alpha numeric characters, except space
      if (value) setShowButton(true);
      else setShowButton(false);
    },
    [setFieldValue]
  );

  return (
    <Form
      sx={{
        display: 'flex',
        mt: 4,
        maxWidth: ['100%', 360],
      }}
    >
      <Box sx={{ width: '100%', flex: 1 }}>
        <FormField name="code" onChange={onInputChange} label={translate('discount.add')} />
      </Box>
      {showButton && (
        <Button sx={{ alignSelf: 'flex-start', mt: 24, ml: 2 }} type="submit">
          {isSubmitting ? (
            <Box sx={{ position: 'relative' }}>
              <Spinner color="white" />
              <span sx={{ visibility: 'hidden', opacity: 0 }} aria-hidden="true">
                {translate('discount.redeem')}
              </span>
            </Box>
          ) : (
            translate('discount.redeem')
          )}
        </Button>
      )}
    </Form>
  );
};

const DiscountFormik = withFormik({
  mapPropsToValues: () => ({
    code: '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      code: yup.string().min(3, translate('discount.tooShort')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'DiscountFormik',
})(DiscountForm);

export default ({ checkDiscount }) => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      const code = values.code.replace(/ /g, '');
      const errorCode = checkDiscount ? await checkDiscount(code) : await dispatch(applyDiscount(code));
      if (errorCode) {
        setFieldError('code', translate(`discount.${errorCode}`));
      }
    },
    [dispatch, checkDiscount, translate]
  );
  const formProps = { onSubmit, translate };

  return <DiscountFormik {...formProps} />;
};

/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../components/Button';
import { removeDiscount } from '../state/basket';
import { formatPrice } from '../utils/formatters';

const RemoveButton = ({ removeFunc }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const removeFromBasket = useCallback(() => dispatch(removeDiscount()), [dispatch]);

  return (
    <div
      sx={{
        color: 'primary',
        fontWeight: 'medium',
        flexGrow: 1,
      }}
    >
      <Button variant="plain" onClick={removeFunc || removeFromBasket} sx={{ ml: 3, p: 0 }}>
        {translate('basket.removeItem')}
      </Button>
    </div>
  );
};

export default ({ discount, amount, removeDiscount, ...props }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Box
      sx={{
        py: [2, 3],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'border',
      }}
    >
      <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 3 }}>
        <div>
          {translate('discount.code')} {discount.code}
        </div>
        <RemoveButton removeFunc={removeDiscount} />
        <div sx={{ color: 'primary', fontWeight: 'medium' }}>{formatPrice(-amount)}</div>
      </Flex>
    </Box>
  );
};

/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFormik } from 'formik';
import 'url-search-params-polyfill'; // for IE
import * as yup from 'yup';
import AddDiscountCode from '../parcelFlow/AddDiscountCode';
import DiscountCodeRow from '../parcelFlow/DiscountCodeRow';
import ServiceCard from '../parcelFlow/ServiceCard';
import Button from '../components/Button';
import Container from '../components/Container';
import FullHeightColumn from '../components/FullHeightColumn';
import { InfoIcon } from '../components/Icon';
import Layout from '../components/layout';
import { PaymentSelection } from '../components/Payment';
import Spinner from '../components/Spinner';
import Tooltip from '../components/Tooltip';
import AddressForm from '../delivery/AddressForm';
import { showNotification } from '../state/notifications';
import { isBrowser } from '../utils';
import * as api from '../utils/api';
import * as analytics from '../utils/analytics';
import { formatPrice } from '../utils/formatters';
import { useTranslate, useLanguage } from '../utils/getLanguage';
import { setOrderId } from '../utils/order';
import { postalCodeRegExp } from '../utils/regExp';
import * as streetAndApartment from '../utils/streetAndApartment';
import { capitalize } from '../utils/string';

const STEP = {
  SELECT: 0,
  ADDRESS: 1,
  CONFIRM: 2,
  PAYMENT: 3,
  RETURN: 4,
  INVALID_PARAMETERS: 5,
  NOT_FOUND: 6,
  DONE: 7,
  ORDER: 8,
};

const BackButton = ({ goBack }) => {
  const translate = useTranslate();
  return (
    <Box>
      <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
        {translate('newDelivery.back')}
      </Button>
    </Box>
  );
};

const ButtonBox = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '200px',
        justifySelf: 'flex-end',
        gridGap: 2,
        flex: 'none',
        justifyContent: ['flex-end', null, 'flex-start'],
        mt: 3,
      }}
    >
      {children}
    </Box>
  );
};

export const ariaLabelForService = (service, translate) => {
  const translate2 = (id, fallback, params) => translate(id, params || {}, { onMissingTranslation: () => fallback });
  const title = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);
  const description = translate2(`buyParcel.services.${service.serviceCode}.description`, service.description, service);
  return `${title}, ${service.price} €, ${description}`;
};

const ServiceList = ({ services, selectedOption, select }) => {
  const translate = useTranslate;
  const container = useRef();
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.service'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : services.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < services.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const service = services[i];
            if (service) {
              setImmediate(() => {
                select(service.serviceCode);
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [services, setFocusIndex, select]
  );

  return (
    <Box
      ref={container}
      sx={{ maxWidth: 640 }}
      role="group"
      aria-label={translate('buyParcel.services.title')}
      onKeyDown={onKeyDown}
    >
      {services.map((s, index) => {
        const isSelected = s.serviceCode === selectedOption;
        return (
          <Box
            sx={{ display: 'relative', width: '100%', my: 2 }}
            key={s.serviceCode}
            onClick={() => select(s.serviceCode)}
            className="service"
            role="checkbox"
            aria-label={ariaLabelForService(s, translate)}
            aria-checked={isSelected}
            tabIndex={focusIndex === index ? 0 : -1}
          >
            <ServiceCard service={s} isSelected={isSelected} onClick={() => select(s.serviceCode)} />
          </Box>
        );
      })}
    </Box>
  );
};

const deliveryOptions = [
  {
    serviceCode: 'KJ',
    displayName: 'Kotijakelu',
    price: 0,
    priceVat0: 0,
  },
  {
    serviceCode: 'JA',
    displayName: 'Jakelu vastaanottajalle',
    price: 0,
    priceVat0: 0,
  },
  {
    serviceCode: 'NMH',
    displayName: 'Nouto Matkahuollosta',
    price: 0,
    priceVat0: 0,
  },
];

const SelectMethod = ({ data, selection, setSelection, goToStep, goNext }) => {
  const translate = useTranslate();
  const services = useMemo(() => {
    if (!data.prices) return deliveryOptions;
    return deliveryOptions.map(op => ({
      ...op,
      ...(data.prices[op.serviceCode] || {}),
    }));
  }, [data.prices]);
  const selectReturn = useCallback(() => {
    setSelection('NMH');
    goToStep(STEP.RETURN);
  }, [setSelection, goToStep]);

  return (
    <Box>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('newDelivery.title')}</Styled.h1>
      <p sx={{ fontWeight: 'medium', mb: 0 }}>{translate('newDelivery.select')}</p>
      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: ['space-between'],
          alignItems: 'flex-start',
        }}
      >
        <ServiceList services={services} selectedOption={selection} select={setSelection} />
        <Flex sx={{ alignItems: 'center' }}>
          <Button sx={{ mr: 3 }} variant="plain" onClick={selectReturn}>
            {translate('newDelivery.noDelivery')}
          </Button>
          <Tooltip
            placement="right"
            tooltip={
              <>
                {translate('newDelivery.areYouSureDescription')}
                <br />
                <br />
                {translate('newDelivery.areYouSureDescription2')}
              </>
            }
          >
            <InfoIcon color="primary" />
          </Tooltip>
        </Flex>
        <Button
          sx={{
            alignSelf: ['flex-end', null, 'flex-start'],
            mt: 3,
          }}
          onClick={() => (selection === 'NMH' ? goToStep(STEP.CONFIRM) : goNext())}
          disabled={!selection}
        >
          {translate('buyParcel.continue')}
        </Button>
      </Flex>
    </Box>
  );
};

const addressSchema = (translate, { askName, askPhone, askRemarks }) => {
  let schema = yup.object().shape({
    street: yup.string().required(translate('form.requiredField')),
    apartment: yup.string(),
    postcode: yup
      .string()
      .required(translate('form.requiredField'))
      .matches(postalCodeRegExp, translate('form.invalidPostcode')),
    city: yup.string().required(translate('form.requiredField')),
    saveAddress: yup.bool(),
  });
  if (askName) {
    schema = schema.concat(
      yup.object().shape({
        name: yup.string().required(translate('form.requiredField')),
        email: yup.string().required(translate('form.requiredField')),
      })
    );
  }
  if (askPhone) {
    schema = schema.concat(
      yup.object().shape({
        phoneNumber: yup.string().matches(/^\+?[0-9\s]{6,18}$/, translate('form.invalidPhoneNumber')),
      })
    );
  }
  if (askRemarks) {
    schema = schema.concat(
      yup.object().shape({
        remarks: yup.string(),
        unattendedHandover: yup
          .string()
          .oneOf(['yes', 'no'], translate('form.requiredField'))
          .required(translate('form.requiredField')),
      })
    );
  }
  return schema;
};

const AddressFormik = withFormik({
  mapPropsToValues: ({ address, askPhone }) => ({
    name: address.name || '',
    email: address.name || '',
    street: address.street || '',
    apartment: address.apartment || '',
    postcode: address.postcode || '',
    city: address.city || '',
    saveAddress: address.save || false,
    phoneNumber: address.phoneNumber || '',
    remarks: address.remarks || '',
    unattendedHandover: address.unattendedHandover,
    submit: '',
  }),

  validationSchema: ({ translate, ...rest }) => addressSchema(translate, rest),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'AddressForm',
})(AddressForm);

const mapProfile2Address = profile => {
  const [street, apartment] = streetAndApartment.split(profile.streetAddress);
  const name = profile.firstname + ' ' + profile.lastname;
  const postcode = profile.postNumber;
  const phoneNumber = profile.phoneNumber;
  const city = profile.postOffice;
  return {
    name,
    street,
    apartment,
    postcode,
    city,
    saveAddress: !street || !postcode || !city,
    phoneNumber,
  };
};

const EnterAddress = ({ data, setData, selection, goBack, goNext }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.session.user);
  const { shipmentNumber, sender } = data.shipment;

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      let response;
      try {
        response = await api.checkIfDeliveryPossible(shipmentNumber, values.postcode);
      } catch (err) {
        dispatch(showNotification('genericApiError'));
        return;
      }

      if (!response || !response.services || response.services.length === 0) {
        setFieldError('submit', translate('newDelivery.notPossible'));
        return;
      }

      setData(oldData => ({ ...oldData, address: values }));
      goNext();
    },
    [shipmentNumber, dispatch, translate, setData, goNext]
  );

  const formProps = {
    address: data.address || {},
    askName: !user,
    askPhone: true,
    showSaveButton: false,
    askRemarks: selection === 'JA',
    onSubmit,
    translate,
  };

  return (
    <Box>
      <BackButton goBack={goBack} />
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('newDelivery.addressTitle')}</Styled.h1>
      <p sx={{ mb: 0 }}>{translate('newDelivery.description', { shipmentNumber, sender })}</p>
      <p sx={{ fontWeight: 'medium' }}>{translate(`newDelivery.${selection}`)}</p>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <AddressFormik {...formProps} />
      </Flex>
    </Box>
  );
};

const ConfirmDelivery = ({ data, setData, selection, goBack, goNext, createOrder, finishWithoutDelivery }) => {
  const serviceCode = selection;
  const translate = useTranslate();
  const { shipment, address, discount } = data;
  const { shipmentNumber, sender } = shipment || {};
  const servicePrice = (data.prices[serviceCode] || {}).price || 0.0;
  const totalPrice = Math.max(0.0, servicePrice - (discount?.amount || 0.0));
  const discountAmount = servicePrice - totalPrice;
  const checkDiscount = useCallback(
    async code => {
      if ((code || '').length < 5) {
        return 'tooShort';
      }
      const response = await api.checkDiscountCode(code, serviceCode);
      if (typeof response === 'string') {
        return response;
      }
      setData(oldData => ({ ...oldData, discount: { ...response, code } }));
    },
    [setData, serviceCode]
  );
  const removeDiscount = useCallback(() => {
    setData(oldData => ({ ...oldData, discount: undefined }));
  }, [setData]);

  const onContinue = useCallback(() => {
    if (selection === 'NMH') {
      finishWithoutDelivery('pickup');
      return;
    }
    if (totalPrice > 0) {
      goNext();
    } else {
      createOrder({ paymentMethod: 'FREE' });
    }
  }, [totalPrice, goNext, createOrder, finishWithoutDelivery, selection]);

  return (
    <FullHeightColumn>
      <Box sx={{ maxWidth: 640 }}>
        <BackButton goBack={goBack} />
        <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('newDelivery.confirmTitle')}</Styled.h1>
        {selection === 'NMH' ? (
          <>
            <p>{translate('newDelivery.confirmSp', { shipmentNumber, sender })}</p>
          </>
        ) : (
          <>
            <p sx={{ mb: 0 }}>{translate('newDelivery.confirmDetails', { shipmentNumber, sender })}</p>
            <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 3, fontWeight: 'medium' }}>
              <div sx={{ flex: 1, fontWeight: 'medium' }}>{translate(`newDelivery.${serviceCode}`)}</div>
              {servicePrice > 0 && (
                <div sx={{ ml: 2, fontWeight: 'medium', color: 'primary' }}>{formatPrice(servicePrice)}</div>
              )}
            </Flex>

            {address && (
              <p>
                <span sx={{ fontWeight: 'medium' }}>{translate('newDelivery.recipient')}: </span>
                {address.phoneNumber && (
                  <>
                    {address.phoneNumber}
                    <br />
                  </>
                )}
                {streetAndApartment.combine(address)}, {address.postcode} {address.city}
              </p>
            )}

            {discount ? (
              <DiscountCodeRow discount={discount} amount={discountAmount} removeDiscount={removeDiscount} />
            ) : (
              servicePrice > 0.0 && <AddDiscountCode checkDiscount={checkDiscount} />
            )}
          </>
        )}
        <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 3, fontWeight: 'medium' }}>
          <div sx={{ flexGrow: 1, mr: 2 }}>{translate('newDelivery.total')}</div>
          <div sx={{ color: 'primary', fontWeight: 'medium' }}>{formatPrice(totalPrice)}</div>
        </Flex>
      </Box>
      <ButtonBox>
        <Button sx={{ mt: 4 }} onClick={onContinue}>
          {totalPrice > 0 ? translate('newDelivery.pay') : translate('newDelivery.continue')}
        </Button>
      </ButtonBox>
    </FullHeightColumn>
  );
};

const Payment = ({ setSelection, goBack, createOrder }) => {
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      const order = await createOrder(paymentOptions);
      if (order && order.redirectUrl) {
        setOrderId(order.orderId, order.transactionId);
        isBrowser && window.location.assign(order.redirectUrl);
        return;
      }
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return (
    <Box>
      <BackButton goBack={goBack} />
      <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />
    </Box>
  );
};

const ReturnShipment = ({ setSelection, goBack, finishWithoutDelivery }) => {
  const translate = useTranslate();
  const confirmReturn = useCallback(async () => {
    finishWithoutDelivery(false);
  }, [finishWithoutDelivery]);

  return (
    <Box>
      <BackButton goBack={goBack} />
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('newDelivery.areYouSure')}</Styled.h1>
      <p>{translate('newDelivery.areYouSureDescription')}</p>
      <p>{translate('newDelivery.areYouSureDescription2')}</p>
      <ButtonBox>
        <Button sx={{ mt: 4 }} onClick={confirmReturn}>
          {translate('newDelivery.continue')}
        </Button>
      </ButtonBox>
    </Box>
  );
};

const NoDeliveryConfirmed = ({ data, selection, pickupPoint }) => {
  const translate = useTranslate();
  const { shipment } = data;
  const { shipmentNumber, sender } = shipment || {};
  const { officeCode, officeName, officeStreetAddress, officePostalCode, officeCity } = pickupPoint || {};

  return (
    <Box>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('newDelivery.done')}</Styled.h1>
      {selection === 'service-point' && <p>{translate('newDelivery.doneSp', { shipmentNumber, sender })}</p>}
      {selection === 'not-receive' && <p>{translate('newDelivery.doneReturn', { shipmentNumber, sender })}</p>}
      {pickupPoint && (
        <div>
          <p sx={{ fontWeight: 'medium' }}>{translate('newDelivery.pickupPoint')}:</p>
          <p>
            {capitalize(officeName)}
            <br />
            {capitalize(officeStreetAddress)}, {officePostalCode} {capitalize(officeCity)}
          </p>
        </div>
      )}
    </Box>
  );
};

const OrderInfo = ({ data }) => {
  const translate = useTranslate();
  const { shipment, order } = data;
  const { shipmentNumber, sender } = shipment || {};
  const { shipmentNumber: newShipmentNumber } = order || {};

  return (
    <Box sx={{ maxWidth: 640 }}>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('newDelivery.done')}</Styled.h1>
      <p sx={{ mb: 0 }}>{translate('delivery.confirmation.newShipmentNumber', { newShipmentNumber })}</p>
    </Box>
  );
};

export default ({ pageContext, location }) => {
  analytics.usePageCategory('paketit');
  const dispatch = useDispatch();
  const language = useLanguage();
  const translate = useTranslate();
  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';
  const user = useSelector(state => state.session.user);

  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');
  const token = isBrowser && new URLSearchParams(location.search).get('token');
  const invalidParameters = isBrowser && (!shipmentNumber || !token);
  const [data, setData] = useState();
  const isLoading = !invalidParameters && (!data || Object.keys(data).length === 0);
  const [selection, setSelection] = useState();
  const [pickupPoint, setPickupPoint] = useState();

  const [steps, setSteps] = useState([invalidParameters ? STEP.INVALID_PARAMETERS : 0]);
  const step = useMemo(() => steps[steps.length - 1], [steps]);
  const goToStep = useCallback(
    step => {
      setSteps(steps => [...steps, step]);
    },
    [setSteps]
  );
  const goNext = useCallback(
    chosenStep => {
      const nextStep = Number.isInteger(chosenStep) ? chosenStep : undefined;
      goToStep(nextStep || step + 1);
    },
    [goToStep, step]
  );
  const goBack = useCallback(() => {
    setSteps(steps => steps.slice(0, -1));
  }, [setSteps]);

  useEffect(() => {
    if (data || invalidParameters) {
      return;
    }
    setData({});
    (async () => {
      let response;
      try {
        response = await api.getDeliveryTimes(shipmentNumber, token, language, true);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setSteps([STEP.NOT_FOUND]);
          setData({ shipment: {} });
        } else {
          dispatch(showNotification('genericApiError'));
        }
        return;
      }
      const { shipment, prices, order, selection } = response;
      setData({ shipment, prices, order });
      if (selection?.notReceive) {
        setSelection(selection.notReceive);
        goToStep(STEP.DONE);
      } else if (order) {
        goToStep(STEP.ORDER);
      }
    })();
  }, [data, invalidParameters, setData, setSteps, shipmentNumber, token, dispatch, translate, language, goToStep]);

  useEffect(() => {
    if (!data || data.address) return;
    if (!user) return;
    setData(oldData => ({ ...oldData, address: mapProfile2Address(user) }));
  }, [user, data, setData]);

  const createOrder = useCallback(
    async paymentOptions => {
      const { name, email, street, apartment, postcode, city, phoneNumber, remarks, unattendedHandover } =
        data.address || {};
      const delivery = {
        name,
        email,
        address: { street, apartment, postcode, city },
        phoneNumber,
        time: selection,
        details: {
          deliveryRemarks: remarks,
          unattendedHandover: unattendedHandover === 'yes',
        },
        discount: data.discount,
      };
      try {
        const order = await api.createDeliveryOrder(shipmentNumber, paymentOptions, language, delivery, token);
        if (order && order.redirectUrl) {
          setOrderId(order.orderId, order.transactionId);
          isBrowser && window.location.assign(order.redirectUrl);
        }
      } catch (error) {
        console.warn('Failed to create delivery order:', error);
        dispatch(showNotification('updateFailed'));
      }
    },
    [shipmentNumber, token, selection, data, language, dispatch]
  );

  const finishWithoutDelivery = useCallback(
    async receive => {
      const notReceive = receive ? 'service-point' : 'not-receive';
      try {
        const response = await api.setDeliveryTime(shipmentNumber, {
          notReceive,
          token,
          language,
        });
        if (receive) {
          const { pickupPoint } = response;
          console.log('pickup point', JSON.stringify(pickupPoint, null, 2));
          if (pickupPoint) {
            setPickupPoint(pickupPoint);
          }
        }
        setSelection(notReceive);
        goToStep(STEP.DONE);
      } catch (error) {
        console.warn('Failed to create delivery order:', error);
        dispatch(showNotification('updateFailed'));
      }
    },
    [shipmentNumber, token, language, dispatch, goToStep, setPickupPoint]
  );

  const pageProps = {
    data,
    setData,
    selection,
    setSelection,
    token,
    goNext,
    goBack,
    goToStep,
    createOrder,
    finishWithoutDelivery,
    pickupPoint,
  };

  return (
    <Layout title={translate('newDelivery.title')} paths={paths} locale={locale} showAppBanner="paketit">
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        {isLoading ? (
          <div sx={{ minHeight: '100px', position: 'relative' }}>
            <Spinner size="medium" />
          </div>
        ) : (
          <>
            {(() => {
              switch (step) {
                case STEP.SELECT:
                  return <SelectMethod {...pageProps} />;
                case STEP.ADDRESS:
                  return <EnterAddress {...pageProps} />;
                case STEP.CONFIRM:
                  return <ConfirmDelivery {...pageProps} />;
                case STEP.PAYMENT:
                  return <Payment {...pageProps} />;
                case STEP.RETURN:
                  return <ReturnShipment {...pageProps} />;
                case STEP.NOT_FOUND:
                  return <div>{translate('newDelivery.notFound')}</div>;
                case STEP.INVALID_PARAMETERS:
                  return <div>{translate('newDelivery.invalidParameters')}</div>;
                case STEP.DONE:
                  return <NoDeliveryConfirmed {...pageProps} />;
                case STEP.ORDER:
                  return <OrderInfo {...pageProps} />;
              }
            })()}
          </>
        )}
      </Container>
    </Layout>
  );
};

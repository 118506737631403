/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { ErrorMessage, Field, Form } from 'formik';
import Button from '../components/Button';
import CheckboxField from '../components/CheckboxField';
import FormField from '../components/FormField';
import AutocompleteStreetField from '../components/AutocompleteStreetField';
import PostalCodeAndCityFields from '../components/PostalCodeAndCityFields';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';

export default ({
  errors,
  askName,
  askPhone,
  handleSubmit,
  isSubmitting,
  showSaveButton = true,
  askRemarks = false,
}) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        maxWidth: 640,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      {askName && (
        <>
          <FormField name="name" label={translate('buyParcel.recipient.name')} />
          <FormField name="email" label={translate('buyParcel.recipient.email')} />
        </>
      )}
      <div style={{ display: 'flex' }}>
        <div sx={{ flexGrow: 1, mr: 3, zIndex: 3 }}>
          <AutocompleteStreetField label={translate('address.street')} country="FIorAX" />
        </div>
        <div sx={{ minWidth: '25%' }}>
          <FormField name="apartment" label={translate('address.apartment')} />
        </div>
      </div>
      <PostalCodeAndCityFields country="FIorAX" />
      {showSaveButton && (
        <>
          <Field component={CheckboxField} name="saveAddress" label={translate('delivery.address.save')} />
          <div sx={{ height: '20px' }} />
        </>
      )}
      {askPhone && (
        <>
          <FormField name="phoneNumber" label={translate('account.phoneNumber')} />
        </>
      )}
      {askRemarks && (
        <>
          <FormField name="remarks" label={translate('buyParcel.recipient.remarks')} />
          <RadioFieldGroup
            id="unattendedHandover"
            error={errors.unattendedHandover}
            label={translate('buyParcel.recipient.unattended')}
          >
            <Field
              component={RadioField}
              name="unattendedHandover"
              id="yes"
              label={translate('buyParcel.recipient.unattendedYes')}
            />
            <Field
              component={RadioField}
              name="unattendedHandover"
              id="no"
              label={translate('buyParcel.recipient.unattendedNo')}
            />
          </RadioFieldGroup>
        </>
      )}
      <ErrorMessage name="submit">{msg => <div sx={{ color: 'red', mt: 2 }}>{msg}</div>}</ErrorMessage>
      <Flex
        sx={{
          justifyContent: ['flex-end', null, 'flex-start'],
          mt: 4,
        }}
      >
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('delivery.address.continue')}
        </Button>
      </Flex>
    </Form>
  );
};
